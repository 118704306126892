var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{staticClass:"px-2 text-jet flex flex-col justify-around",staticStyle:{"height":"82px !important"}},[_c('div',{staticClass:"flex justify-start items-center gap-2"},[_c('p',{staticClass:"text-xs uppercase font-black"},[_vm._v(_vm._s(_vm.type))])]),_c('div',{staticClass:"w-full flex justify-between items-center"},[_c('div',{staticClass:"flex justify-start items-center gap-2"},[_c('icon',{staticClass:"width",attrs:{"icon-name":_vm.iconName,"size":"xs"}}),_c('div',[_c('div',{staticClass:"flex"},[_c('p',{staticClass:"mr-2 font-black size uppercase"},[_vm._v("Total Gross Pay")]),(_vm.type === `Variance` && _vm.trend)?_c('icon',{attrs:{"icon-name":_vm.trend.grossPay === 'upward' ?
                    'growth-icon' :
                    _vm.trend.grossPay === 'downward'? 'red-growth-icon' : '',"size":"xs"}}):_vm._e()],1),_c('p',{staticClass:"text-base font-semibold"},[_vm._v(_vm._s(_vm.amount ? _vm.convertToCurrency(_vm.amount) : 0))])])],1),_c('div',{staticClass:"flex justify-start items-center gap-2"},[_c('div',{staticClass:"h-5 border border-solid border-jet"}),_c('div',[_c('div',{staticClass:"flex"},[_c('p',{staticClass:"mr-2 font-black size uppercase"},[_vm._v("Total Net Pay")]),(_vm.type === `Variance` && _vm.trend)?_c('icon',{attrs:{"icon-name":_vm.trend.netPay === 'upward' ?
                    'growth-icon' :
                    _vm.trend.netPay === 'downward'? 'red-growth-icon' : '',"size":"xs"}}):_vm._e()],1),_c('p',{staticClass:"text-base font-semibold"},[_vm._v(_vm._s(_vm.netAmount ? _vm.convertToCurrency(_vm.netAmount) : 0))])])]),_c('div',{staticClass:"flex justify-start items-center gap-2"},[_c('div',{staticClass:"h-5 border border-solid border-jet"}),_c('div',[_c('div',{staticClass:"flex"},[_c('p',{staticClass:"mr-2 font-black size uppercase"},[_vm._v("Employee Count")]),(_vm.type === `Variance` && _vm.trend)?_c('icon',{attrs:{"icon-name":_vm.trend.empCount === 'upward' ?
                    'growth-icon' :
                    _vm.trend.empCount === 'downward'? 'red-growth-icon' : '',"size":"xs"}}):_vm._e()],1),_c('p',{staticClass:"text-base font-semibold"},[_vm._v(_vm._s(_vm.empCount ? _vm.empCount : 0))])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }