<template>
    <Card class=" px-2 text-jet flex flex-col justify-around" style="height:82px !important">
        <div class="flex justify-start items-center gap-2">
            <p class="text-xs uppercase font-black">{{ type }}</p>
<!--            <icon v-if="type === `Variance`" icon-name="growth-icon" size="xs"/>-->
        </div>
        <div class="w-full flex justify-between items-center">
            <div class="flex justify-start items-center gap-2">
                <icon :icon-name="iconName" class="width" size="xs"/>
                <div>
                    <div class="flex">
                    <p class="mr-2 font-black size uppercase">Total Gross Pay</p>
                    <icon v-if="type === `Variance` && trend" :icon-name="trend.grossPay === 'upward' ?
                    'growth-icon' :
                    trend.grossPay === 'downward'? 'red-growth-icon' : ''"
                          size="xs"/>

                    </div>
                    <p class="text-base font-semibold">{{ amount ? convertToCurrency(amount) : 0  }}</p>
                </div>
            </div>
          <div class="flex justify-start items-center gap-2">
            <div class="h-5 border border-solid border-jet"></div>
                <div>
                    <div class="flex">
                      <p class="mr-2 font-black size uppercase">Total Net Pay</p>
                      <icon v-if="type === `Variance` && trend" :icon-name="trend.netPay === 'upward' ?
                    'growth-icon' :
                    trend.netPay === 'downward'? 'red-growth-icon' : ''"
                            size="xs"/>
                    </div>
                    <p class="text-base font-semibold">{{ netAmount ? convertToCurrency(netAmount) : 0  }}</p>
                </div>
            </div>
            <div class="flex justify-start items-center gap-2">
                <div class="h-5 border border-solid border-jet"></div>
                <div>
                    <div class="flex">
                      <p class="mr-2 font-black size uppercase">Employee Count</p>
                      <icon v-if="type === `Variance` && trend" :icon-name="trend.empCount === 'upward' ?
                    'growth-icon' :
                    trend.empCount === 'downward'? 'red-growth-icon' : ''"
                            size="xs"/>
                    </div>
                    <p class="text-base font-semibold">{{  empCount ? empCount : 0 }}</p>
                </div>
            </div>
        </div>
    </Card>
</template>

<script>
import Card from "@/components/Card";

    export default {
        name: "TrendState",
        props: {
            type: {
                type: String,
                default: ""
            },
            amount: {
                type: Number,
                default: 0
            },
            netAmount: {
                type: Number,
                default: 0
            },
            empCount: {
                type: Number,
                default: 0
            },
            iconName: {
                type: String,
                default: ""
            },
            trend: {
                type: Object,
                default: null
            }
        },
        components: {
            Card,
        },
        data() {
            return {};
        },
    };
</script>

<style scoped>
.card{
    width: calc(100% / 3) !important;
    height: 82px !important;
}
.width{
    width: 20px !important;
    height: 22px !important;
}
.size{
    font-size: 10px;
}
</style>
